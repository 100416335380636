@font-face {
    font-family: biotif-extrabold;
    src: url(../fonts/biotif/Biotif-ExtraBold.ttf);
}
@font-face {
    font-family: biotif-bold;
    src: url(../fonts/biotif/Biotif-Bold.ttf);
}
@font-face {
    font-family: biotif-medium;
    src: url(../fonts/biotif/Biotif-Medium.ttf);
}

.navbar,
.navbar-inverse {
    background-color: #1A1414 !important;
}

.navbar-inverse .navbar-nav>li>a {
    font-family: biotif-medium;
    color: white !important;
}

.spotify-icon{
    padding-right: 0px;
}

.nav-item{
    margin-bottom: 0px;
}

.nav-item:hover{
    color: #1ED760 !important;
}

.pure-music-button{
    margin: -10px;
    border: none;
    padding-left: 0px;
    color: white;
    background-color: transparent;
}
.pure-music-button:focus {
    outline: none;
}

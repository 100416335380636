@font-face {
    font-family: biotif-bold;
    src: url(../fonts/biotif/Biotif-Bold.ttf);
}
@font-face {
    font-family: biotif-medium;
    src: url(../fonts/biotif/Biotif-Medium.ttf);
}
@font-face {
    font-family: biotif-regular;
    src: url(../fonts/biotif/Biotif-Regular.ttf);
}

html {
    overflow:   scroll;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.pure-music-title{
    font-family: biotif-medium;
    font-size: 100px;
    margin-bottom: 0px;
    color: black;
}

.pure-music-description{
    font-family: biotif-medium;
    font-size: 20px;
    margin-bottom: 0px;
    color: black;
    padding-bottom: 30px;
}

.main-button{
    color: white;
    font-family: biotif-bold;
    background-color: #509bf5;
    font-size: 14px;
    line-height: 1;
    border-radius: 500px;
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    transition-duration: .3s !important;
    border-width: 0 !important;
    letter-spacing: 2px !important;
    text-transform: uppercase;
    white-space: normal !important;
}

.main-button:hover{
    color: white;
    background-color: #75B0F4 !important;
}

.credits{
    font-family: biotif-regular;
    bottom: 0;
    right: 0;
    padding-top: 30px;
    padding-right: 10px;
    float: right;
}

.about-section{
    padding-top: 20px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

}

.about-section > .ant-card{
    flex-grow: 1;
    margin: 10px !important;
}

.about-section-heading{
    font-family: biotif-regular;
    font-size: 30px;
    margin-bottom: 4px;
    color: black;
}

.about-section-content{
    font-family: biotif-regular;
    font-size: 14px;
    color: black;
}

.demo-image{
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 660px) {
    .about-section{
        display: flex;
        flex-direction: column !important;
    }
    .about-section > .ant-card{
        width: 100% !important;
        margin: 10px !important;
    }
}

@media screen and (max-width: 525px) {
    .pure-music-title{
        font-size: 80px;
    }
}

@media screen and (max-width: 425px) {
    .pure-music-title{
        font-size: 60px;
    }
}

@media screen and (max-width: 335px) {
    .pure-music-title {
        font-size: 30px;
    }
    .pure-music-description{
        font-size: 15px;
    }
}

@media screen and (max-width: 271px) {
    .main-button{
        font-size: 10px;
    }
}

@media screen and (max-width: 235px) {
    .about-section-heading{
        font-size: 18px;
    }
    .about-section-content{
        font-size: 12px;
    }
}

@media screen and (max-width: 180px) {
    .pure-music-title{
        font-size: 20px;
    }
    .pure-music-description{
        font-size: 10px;
    }
    .main-button{
        font-size: 6px;
    }
}







@font-face {
    font-family: biotif-extrabold;
    src: url(../fonts/biotif/Biotif-ExtraBold.ttf);
}
@font-face {
    font-family: biotif-bold;
    src: url(../fonts/biotif/Biotif-Bold.ttf);
}
@font-face {
    font-family: biotif-medium;
    src: url(../fonts/biotif/Biotif-Medium.ttf);
}
@font-face {
    font-family: biotif-regular;
    src: url(../fonts/biotif/Biotif-Regular.ttf);
}

.playlist-container{
    display:-webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

}

.playlist-item{
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.playlist-name{
    font-family: biotif-extrabold;
    font-size: 20px;
    margin-bottom: 0px;
    text-overflow: clip;

}

.playlist-content{
    font-family: biotif-bold;
    font-size: 10px;
    padding-bottom: 10px;
}


.clean-playlist-button{
    color: white !important;
    font-family: biotif-bold !important;
    background-color: #509bf5 !important;
    font-size: 14px !important;
    line-height: 1 !important;
    border-radius: 500px !important;
    padding-top: 15px !important;
    padding-right: 25px !important;
    padding-bottom: 25px !important;
    padding-left: 25px !important;
    transition-duration: .3s !important;
    border-width: 0 !important;
    letter-spacing: 2px !important;
    text-transform: uppercase !important;
    white-space: normal !important;

}
.clean-playlist-button:hover{
    background-color: #75B0F4 !important;
}

.ant-modal-content .ant-btn{
    display: none;
}
.ant-modal-content .ant-btn-primary{
    display: flex !important;
    width: 100%;
    margin: 0px;
    text-align: center !important;
}

.ant-spin-text{
    font-family: biotif-regular;
}

.ant-steps-item-title{
    font-family: biotif-medium;
}
.ant-steps-item-description{
    font-family: biotif-regular;
}






